<template>
    <th
        slot-scope='props'
        v-on='props.thEvents'
        v-bind='props.thAttrs'
        :aria-label="props.heading === '' ? 'Empty Select' : props.heading"
        :class='{"user-th": props.heading === "User", "created-at-th": props.heading === "Created at"}'
    >
    <span
        class='custom-input'
        :title='props.title'
        v-if="props.heading === 'Select'"
    >
      <label for='select_all' class='visually-hidden'>
        Select all items
      </label>
      <input type='checkbox' id='select_all' aria-label='Select all items' />
    </span>
        <span
            class='VueTables__heading'
            :title='props.title'
            v-else-if='props.heading.length > 0'
        >
      <span
          :class="{ clickable: props.heading !== 'Actions' }"
          @click='updateSortBy(props.heading)'
      >
        <!-- Conditional icons based on heading -->
        <font-awesome-icon
            icon='lock'
            v-if="props.heading === 'Is encrypted'"
        />
        <font-awesome-icon
            icon='heart'
            v-if="props.heading === 'Is favorite'"
            class='text-primary'
        />
        <font-awesome-icon
            icon='archive'
            v-if="props.heading === 'Is archive'"
        />
        <font-awesome-icon
            icon='shield-virus'
            v-if="props.heading === 'Has Endpoint Protection'"
        />
        <font-awesome-icon
            icon='cloud-upload-alt'
            v-if="props.heading === 'Has backup'"
        />
        <font-awesome-icon
            icon='user-secret'
            v-if="props.heading === 'Has agent'"
        />
        <font-awesome-icon
            icon='shield'
            v-if="props.heading === 'Is private'"
        />

        {{ toTitleCase(props.heading) }}

          <!-- Sorting icons if not 'Actions' -->
        <template v-if="props.heading !== 'Actions'">
          <!-- Loading spinner -->
          <font-awesome-icon
              icon='spinner'
              size='1x'
              spin
              v-if='loading'
              class='float-end'
          />
            <!-- Sort indicators -->
          <template v-else>
            <font-awesome-icon
                icon='sort-up'
                v-if="
                toTitleCase(currentSortByTitle) === toTitleCase(props.heading) &&
                currentSortDirection === 'ascending'
              "
                class='float-end'
            />
            <font-awesome-icon
                icon='sort-down'
                v-else-if="
                toTitleCase(currentSortByTitle) === toTitleCase(props.heading) &&
                currentSortDirection === 'descending'
              "
                class='float-end'
            />
            <font-awesome-icon
                icon='sort'
                v-else
                color='gray'
                class='float-end'
            />
          </template>
        </template>
      </span>
    </span>
        <vt-sort-control />
    </th>
</template>

<script>
    /**
     * This version uses Vue 3's Options API with Pinia.
     */
    import { h } from 'vue';
    import VtSortControl from 'v-tables-3/compiled/components/VtSortControl';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    // Pinia store
    import { useMainStore } from '@/stores/main';

    // Utility functions
    import { columnTitleCase, words_to_replace } from '@/composables/utils';
    import { get_lookup_fields } from '@/composables/load-lookups';

    export default {
        name: 'VtTableHeading',
        components: {
            FontAwesomeIcon,
            VtSortControl,
            vnodes: {
                functional: true,
                render: (ctx) =>
                    typeof ctx.$attrs.vnodes === 'object'
                        ? h(ctx.$attrs.vnodes)
                        : [h(ctx.$attrs.vnodes)]
            }
        },
        props: ['props'],

        data() {
            return {
                // Local loading spinner
                loading: false
            };
        },

        computed: {
            // Access the Pinia store instance via a computed getter
            mainStore() {
                return useMainStore();
            },

            // Mirror store state/getters into local computed
            currentSortBy() {
                return this.mainStore.currentSortBy;
            },
            currentSortDirection() {
                return this.mainStore.currentSortDirection;
            },
            currentSortByTitle() {
                return this.mainStore.currentSortByTitle;
            }
        },

        methods: {
            toTitleCase(str) {
                return columnTitleCase(str);
            },

            updateSortBy(heading) {
                this.mainStore.setSortByTitle(heading);
                this.loading = true;

                // Flip the words_to_replace
                const wordsToReplaceFlipped = {};
                for (const [key, value] of Object.entries(words_to_replace)) {
                    wordsToReplaceFlipped[value] = key;
                }

                // Convert heading to a potential DB column
                let originalHeading = heading.toLowerCase();
                for (const [key, value] of Object.entries(wordsToReplaceFlipped)) {
                    const regex = new RegExp(key, 'g');
                    originalHeading = originalHeading.replace(regex, value);
                }

                // Replace spaces with underscores
                originalHeading = originalHeading.replace(/ /g, '_');

                // Check for a matching lookup field
                const lookupFields = get_lookup_fields();
                if (lookupFields.includes(originalHeading + '_id')) {
                    originalHeading = originalHeading + '_id';
                }

                // Toggle sort direction if same column, else set new column
                if (this.mainStore.currentSortBy === originalHeading) {
                    const newDirection =
                        this.mainStore.currentSortDirection === 'ascending'
                            ? 'descending'
                            : 'ascending';
                    this.mainStore.setSortDirection(newDirection);
                } else {
                    this.mainStore.setSortBy(originalHeading);
                }

                // Simulate loading
                setTimeout(() => {
                    this.loading = false;
                }, 500);
            }
        }
    };
</script>

<style scoped>
    .user-th {
        width: 250px;
    }

    .created-at-th {
        width: 200px;
    }
</style>
